<template>
  <div>
    <div v-for="subscription in subscriptionList" :key="subscription.id">
      <template>
        <b-card no-body class="p-3 mb-2">
          <b-row class="no-gutters align-items-center">
            <b-col class="col-auto mr-3">
              <img :src="clientTypeImg" class="user__icon" />
            </b-col>
            <b-col>
              <div class="font-weight-bold">
                {{ subscription.clientId.firstName }} {{ subscription.clientId.lastName }}
              </div>
              <div class="font-weight-bold">
                {{ subscription.serviceId.name }}
              </div>
            </b-col>
            <b-col class="" v-if="subscription.serviceId.numAnalysis !== 0">{{ $t("page.subscriptions.analysisUsed") }} {{
              subscription.analysisUsed }}/{{ subscription.serviceId.numAnalysis }}</b-col>
            <b-col class="" v-else>{{ $t("page.subscriptions.unlimited") }}</b-col>
            <b-col class="col-auto">
              <b-button class="mb-1" size="sm" variant="outline-primary"
                @click="viewDetails(subscription.serviceId._id)">
                {{ $t("button.details") }}
              </b-button>
            </b-col>
            <b-col class="col-auto ml-1">
              <b-button class="mr-1 mb-1" variant="danger" @click="openDeleteConfirmationDialog(subscription)">
                {{ $t("button.delete") }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </template>
    </div>
    <div v-if="isEmptyList">
      <h5 class="text-center">Empty list</h5>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {
    subscriptionList() {
      return this.$store.getters["subscriptionPredict/getSubscriptionList"];
    },
    isEmptyList() {
      return this.subscriptionList.length === 0;
    },
    clientTypeImg() {
      if (this.isClientMultiuser) {
        return require("./../../assets/icons/users.svg");
      } else {
        return require("./../../assets/icons/user.svg");
      }
    },
  },
  methods: {
    viewDetails(subscriptionId) {
      this.$router.push({ name: 'Service-Details', params: { id: subscriptionId } })
    },
    openDeleteConfirmationDialog(subscription) {
      const subscriptionName = subscription.serviceId.name; 
      this.$store.commit("confirm/SET_CONFIRM", {
        titleText: "dialog.confirm.deleteSubscriptionTitle",
        messageText:"dialog.confirm.deleteSubscriptionMessage",
        messageParams: { subscriptionName },
        confirmBtnCallback: () => this.deleteSubscription(subscription._id), 
        type: "DELETE",
      });
    },

    deleteSubscription(subscriptionId) {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("subscriptionPredict/deleteSubscription", subscriptionId)
        .then(() => { })
        .catch(() => { })
        .finally(() => this.$store.dispatch("loader/close"));
      },
      

  }
  
};
</script>

<style scoped>
.user__icon {
  width: 20px;
  filter: invert(0%) sepia(85%) saturate(7500%) hue-rotate(173deg) brightness(87%) contrast(113%);
}
</style>